<template>
    <div class="page-main" >
        <div class="left-menu">
            <div class="menu-item" :class="{'active':type==='sendText'}" @click="updateTabActive('sendText')">文字</div>
            <div class="menu-item" :class="{'active':type==='sendImage'}" @click="updateTabActive('sendImage')">图片</div>
            <div class="menu-item" :class="{'active':type==='sendVideo'}" @click="updateTabActive('sendVideo')">视频</div>
            <div class="menu-item" :class="{'active':type==='sendLink'}" @click="updateTabActive('sendLink')">链接</div>
            <div class="menu-item" :class="{'active':type==='sendApp'}" @click="updateTabActive('sendApp')">小程序</div>
        </div>
        <div class="main-content" style="position:relative">
            
            <div class="text-content" v-if="type==='sendText'">
                <a-form layout="inline">
                    <a-form-item
                        label="标题"
                    >
                        <a-input v-model="searchTitle" placeholder="请输入标题"/>
                    </a-form-item>
                    <a-form-item>
                        <a-button type="primary" icon="search" @click="handleClickSearchText">查询</a-button>
                    </a-form-item>
                </a-form>
                <a-row class="text-title">
                   <a-button type="danger" @click="handleDelete">删除</a-button> <a-button type="primary" @click="showAddText=true">新增</a-button>
                </a-row>
                <a-table
                    :columns="textColumns"
                    :data-source="textDataList"
                    :row-key="(record) => record._id"
                    :pagination="textPagination"
                    :loading="loading"
                    :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
                    @change="handleTextTableChange"
                    >
                    <div slot="time" slot-scope="text">
                        {{text | parseTime('{y}-{m}-{d} {h}:{i}:{s}')}}
                    </div>
                     <template slot="operation" slot-scope="text,record">
                            <div class="operation-wrapper">
                                <a-button type="link" @click="updateText(record)">编辑</a-button>
                            </div>  
                        </template>
                </a-table>
            </div>
            <div class="img-content" v-if="type==='sendImage'">
                <a-form layout="inline">
                    <a-form-item
                        label="标题"
                    >
                        <a-input v-model="searchTitle" placeholder="请输入标题"/>
                    </a-form-item>
                    <a-form-item>
                        <a-button type="primary" icon="search" @click="handleClickSearchImg">查询</a-button>
                    </a-form-item>
                </a-form>
                <a-row class="btn-view">
                    <a-button type="primary" :disabled="!imgPagination.hasPrev" @click="handleGetImgsPrevPage">上一页</a-button>
                    <a-button style="margin:0px 10px" type="primary" :disabled="!imgPagination.hasNext" @click="handleGetImgsNextPage">下一页</a-button>
                    <a-button style="margin-right:10px" @click="isChooseClick = !isChooseClick" :disabled="imgList.length===0">{{isChooseClick?'取消':'选择'}}</a-button>
                    <a-button type="danger" style="margin-right:10px" v-if="isChooseClick" :disabled="selectImgList.length===0" @click="handleDelete">删除</a-button>
                    <a-button type="primary" icon="plus" @click="showAddImg=true">新增</a-button>
                </a-row>
                <div class="img-list">
                    <div class="img-item" v-for="(item,index) in imgList" :key="index">
                        <img :src="item.content" class="img" @click="handlePreview(item)"/>
                        <p class="title">{{item.title}}</p>
                        <div class="check-view" v-if="isChooseClick">
                            <a-checkbox @change="getChecked(item,$event)">
                            </a-checkbox>
                        </div>
                        
                    </div>
                </div>
                <a-modal title="预览图片" :visible="previewImgVisible" :footer="null" @cancel="previewImgVisible=false">
                    <img alt="example" style="width: 100%" :src="previewImage" />
                </a-modal>
            </div>
            <div class="video-content" v-if="type==='sendVideo'">
                <a-form layout="inline">
                    <a-form-item
                        label="标题"
                    >
                        <a-input v-model="searchTitle" placeholder="请输入标题"/>
                    </a-form-item>
                    <a-form-item>
                        <a-button type="primary" icon="search" @click="handleClickSearchVideo">查询</a-button>
                    </a-form-item>
                </a-form>
                <a-row class="btn-view">
                    <a-button type="primary" :disabled="!videoPagination.hasPrev" @click="handleGetVideosPrevPage">上一页</a-button>
                    <a-button style="margin:0px 10px" type="primary" :disabled="!videoPagination.hasNext" @click="handleGetVideosNextPage">下一页</a-button>
                    <a-button style="margin-right:10px" @click="isChooseClick = !isChooseClick" :disabled="videoList.length===0">{{isChooseClick?'取消':'选择'}}</a-button>
                    <a-button type="danger" style="margin-right:10px" v-if="isChooseClick" :disabled="selectVideoList.length===0" @click="handleDelete">删除</a-button>
                    <a-button type="primary" icon="plus" @click="showAddVideo=true">新增</a-button>
                </a-row>
                <div class="img-list">
                    <div class="img-item" v-for="(item,index) in videoList" :key="index">
                        <video :src="item.content" class="img" @click="handlePreview(item)"></video>
                        <p class="title">{{item.title}}</p>
                        <div class="check-view" v-if="isChooseClick">
                            <a-checkbox @change="getChecked(item,$event)">
                            </a-checkbox>
                        </div>
                        
                    </div>
                </div>
                <a-modal title="视频预览" :visible="previewVideoVisible" :footer="null" @cancel="previewVideoVisible= false">
                    <video alt="example" style="width: 100%" :src="previewVideo" controls></video>
                </a-modal>
            </div>
            <div class="link-content" v-if="type==='sendLink'">
                <a-form layout="inline">
                    <a-form-item
                        label="标题"
                    >
                        <a-input v-model="searchTitle" placeholder="请输入标题"/>
                    </a-form-item>
                    <a-form-item>
                        <a-button type="primary" icon="search" @click="handleClickSearchLink">查询</a-button>
                    </a-form-item>
                </a-form>
                <a-row class="btn-view">
                    <a-button type="primary" :disabled="!linkPagination.hasPrev" @click="handleGetLinksPrevPage">上一页</a-button>
                    <a-button style="margin:0px 10px" type="primary" :disabled="!linkPagination.hasNext" @click="handleGetLinksNextPage">下一页</a-button>
                    <a-button style="margin-right:10px" @click="isChooseClick = !isChooseClick" :disabled="linkList.length===0">{{isChooseClick?'取消':'选择'}}</a-button>
                    <a-button type="danger" style="margin-right:10px" v-if="isChooseClick" :disabled="selectLinkList.length===0" @click="handleDelete">删除</a-button>
                    <a-button type="primary" @click="showAddLink=true">新增</a-button>
                </a-row>
                <div class="main">
                    <div class="item" v-for="(item,index) in linkList" :key="index" @mouseover.stop.prevent="activeLink = index" @mouseleave.stop.prevent="activeLink=-1">
                        <a-card class="link-card" >
                            <img
                            slot="cover"
                            alt="example"
                            :src="item.content.image_url"
                            style="height:150px;border-radius:5px 5px 0px 0px"
                            />
                            <a-card-meta :title="item.content.title">
                                <template slot="description">
                                    <div class="description" >{{item.content.desc}}</div>
                                    <div  class="link">{{item.content.content}}</div>
                                </template>
                            </a-card-meta>
                        </a-card>
                        <div class="check-view" v-if="isChooseClick">
                            <a-checkbox @change="getChecked(item,$event)">
                            </a-checkbox>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="app-content" v-if="type==='sendApp'">
                 <a-form layout="inline">
                    <a-form-item
                        label="标题"
                    >
                        <a-input v-model="searchTitle" placeholder="请输入标题"/>
                    </a-form-item>
                    <a-form-item>
                        <a-button type="primary" icon="search" @click="handleClickSearchApp">查询</a-button>
                    </a-form-item>
                </a-form>
                <a-row class="btn-view">
                    <a-button type="primary" :disabled="!appPagination.hasPrev" @click="handleGetAppsPrevPage">上一页</a-button>
                    <a-button style="margin:0px 10px" type="primary" :disabled="!appPagination.hasNext" @click="handleGetAppsNextPage">下一页</a-button>
                    <a-button style="margin-right:10px" @click="isChooseClick = !isChooseClick" :disabled="appList.length===0">{{isChooseClick?'取消':'选择'}}</a-button>
                    <a-button type="danger" style="margin-right:10px" v-if="isChooseClick" :disabled="selectAppList.length===0" @click="handleDelete">删除</a-button>
                   <a-button type="primary" @click="showAddApp=true">新增</a-button>
                </a-row>
                <div class="main">
                    <div class="item" v-for="(item,index) in appList" :key="index">
                        <div class="app-card" >
                           <div class="title">
                               <img :src="item.content.thumburl" class="logo-img"/>
                               <span class="app-name">{{item.content.title}}</span>
                           </div>
                           <div class="content">
                               <p class="desc">{{item.content.des || '   '}}</p>
                               <img :src="item.content.image" class="main-img"/>
                           </div>
                        </div>
                        <div class="check-view" v-if="isChooseClick">
                            <a-checkbox @change="getChecked(item,$event)">
                            </a-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a-modal
            v-model="showAddText"
            title='编辑文字素材'
            @ok="handleAddText"
            @close="emojiShow = false"
            @cancel="handleCloseAddText"
        >
            <a-form>
                <a-form-item
                    label="标题"
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 20 }"
                >
                    <a-input v-model="textForm.title" placeholder="请输入标题"/>
                </a-form-item>
                <a-form-item
                    label="内容"
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 20 }"
                >
                    <a-textarea
                        v-model="textForm.content"
                        placeholder="请输入内容"
                        :maxLength="250"
                        :auto-size="{ minRows: 5 }"
                    ></a-textarea>
                </a-form-item>
                <a-form-item
                    label="表情"
                     :label-col="{span:4}" 
                    :wrapper-col="{ span: 20 }"
                >
                    <div class="left">
                        <span class="iconfont icon-emoji" @mousedown.prevent @click="emojiShow = !emojiShow"></span>
                    </div>
                   
                </a-form-item>
            </a-form>
             <div class="emoji-view element" v-show="emojiShow">
                <div class="emoji-list">
                    <span class="emoji-item hand" v-for="v in emojiList" :key="v" @click="addEmoji(v)">{{v}}</span>
                </div>
            </div>
        </a-modal>
        <a-modal
            title="添加图片"
            v-model="showAddImg"
            @ok="handleAddImg"
            @cancel="handleCloseAddImg"
        >
        <div class="tips">上传素材，图片为jpg、png、jpeg格式,视频为mp4格式</div>
            <a-form>
                <a-form-item
                    label="标题"
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 15 }"
                >
                    <a-input v-model="imgForm.title" placeholder="请输入标题"/>
                </a-form-item>
                <a-form-item
                    label="图片"
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 15 }"
                >
                    <a-upload
                        list-type="picture-card"
                        class="avatar-uploader"
                        accept='.jpg,.jpeg,.png'
                        :show-upload-list="false"
                        action="scrm/upload.file"
                        :before-upload="beforeUpload"
                        @change="handleImgChange"
                    >
                        <img v-if="imgForm.content" :src="imgForm.content" alt="avatar" style="height:100px;width:100px" />
                        <div v-else>
                        <a-icon :type="imgLoading ? 'loading' : 'plus'" />
                        <div class="ant-upload-text">
                            上传
                        </div>
                        </div>
                    </a-upload>
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal
            title="添加视频"
            v-model="showAddVideo"
            @ok="handleAddVideo"
            @cancel="handleCloseAddVideo"
        >
        <div class="tips">上传素材，图片为jpg、png、jpeg格式,视频为mp4格式</div>
            <a-form>
                <a-form-item
                    label="标题"
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 15 }"
                >
                    <a-input v-model="videoForm.title" placeholder="请输入标题"/>
                </a-form-item>
                <a-form-item
                    label="视频"
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 15 }"
                >
                    <a-upload
                        list-type="picture-card"
                        class="avatar-uploader"
                        accept='.mp4'
                        :show-upload-list="false"
                        action="scrm/upload.file"
                        :before-upload="beforeUpload"
                        @change="handleVideoChange"
                    >
                        <video v-if="videoForm.content" :src="videoForm.content" alt="avatar" style="height:100px;width:100px"></video>
                        <div v-else>
                        <a-icon :type="videoLoading ? 'loading' : 'plus'" />
                        <div class="ant-upload-text">
                            上传
                        </div>
                        </div>
                    </a-upload>
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal 
            v-model="showAddLink"
            title='添加链接素材'
            @ok="handleAddLink"
            @cancel="handleCloseAddLink"
        >
        <div class="tips">上传素材，图片为jpg、png、jpeg格式,视频为mp4格式</div>
            <a-form  :form="linkForm">
                <a-form-item 
                    label="标题" 
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 20 }"
                    >
                    <a-input
                        v-model="linkForm.title"
                        placeholder="请输入标题"
                    />
                </a-form-item>
                <a-form-item 
                    label="详细信息"
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 20 }"
                    >
                    <a-input
                        v-model="linkForm.desc"
                        placeholder="请输入详细信息"
                    />
                </a-form-item>
                <a-form-item 
                    label="链接地址"
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 20 }"
                    >
                    <a-input
                        v-model="linkForm.content"
                        placeholder="请输入链接地址"
                    />
                </a-form-item>
                <a-form-item 
                    label="图片"
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 20 }"
                >
                    <a-upload
                        list-type="picture-card"
                        class="avatar-uploader"
                        accept='.jpg,.jpeg,.png'
                        :show-upload-list="false"
                        action="scrm/upload.file"
                        :before-upload="beforeUpload"
                        @change="handleImgChange"
                    >
                        <img v-if="linkForm.image_url" :src="linkForm.image_url" alt="avatar" style="height:100px;width:100px"/>
                        <div v-else>
                        <a-icon :type="imgLoading ? 'loading' : 'plus'" />
                        <div class="ant-upload-text">
                            上传
                        </div>
                        </div>
                    </a-upload>
                </a-form-item>
            </a-form>

        </a-modal>
        <a-modal 
            v-model="showAddApp"
            title='添加小程序'
            @ok="handleAddApp"
            @cancel="handleCloseAddApp"
        >
        <div class="tips">上传素材，图片为jpg、png、jpeg格式,视频为mp4格式</div>
            <a-form  :form="appForm">
                <a-form-item 
                    label="标题" 
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 20 }"
                    >
                    <a-input
                        v-model="appForm.title"
                        placeholder="请输入标题"
                    />
                </a-form-item>
                <a-form-item 
                    label="详细信息"
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 20 }"
                    >
                    <a-input
                        v-model="appForm.des"
                        placeholder="请输入详细信息"
                    />
                </a-form-item>
                <a-form-item 
                    label="小程序地址"
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 20 }"
                    >
                    <a-input
                        v-model="appForm.pagepath"
                        placeholder="请输入小程序地址"
                    />
                </a-form-item>
                <a-form-item 
                    label="ghid"
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 20 }"
                    >
                    <a-input
                        v-model="appForm.ghid"
                        placeholder="请输入ghid"
                    />
                </a-form-item>
                <a-form-item 
                    label="appid"
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 20 }"
                    >
                    <a-input
                        v-model="appForm.appid"
                        placeholder="请输入appid"
                    />
                </a-form-item>
                <a-row>
                    <a-col :span="12">
                        <a-form-item 
                    label="logo"
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 20 }"
                >
                    <a-upload
                        list-type="picture-card"
                        class="avatar-uploader"
                        accept='.jpg,.jpeg,.png'
                        :show-upload-list="false"
                        action="scrm/upload.file"
                        :before-upload="beforeUpload"
                        @change="handleBrandImgChange"
                    >
                        <img v-if="appForm.thumburl" :src="appForm.thumburl" alt="avatar" style="height:100px;width:100px"/>
                        <div v-else>
                        <a-icon :type="brandLoading ? 'loading' : 'plus'" />
                        <div class="ant-upload-text">
                            上传
                        </div>
                        </div>
                    </a-upload>
                </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item 
                    label="主图"
                    :label-col="{span:4}" 
                    :wrapper-col="{ span: 20 }"
                >
                    <a-upload
                        list-type="picture-card"
                        class="avatar-uploader"
                        accept='.jpg,.jpeg,.png'
                        :show-upload-list="false"
                        action="scrm/upload.file"
                        :before-upload="beforeUpload"
                        @change="handleImgChange"
                    >
                        <img v-if="appForm.image" :src="appForm.image" alt="avatar" style="height:100px;width:100px"/>
                        <div v-else>
                        <a-icon :type="imgLoading ? 'loading' : 'plus'" />
                        <div class="ant-upload-text">
                            上传
                        </div>
                        </div>
                    </a-upload>
                </a-form-item>
                    </a-col>
                </a-row>
                
                
            </a-form>

        </a-modal>
    </div>
</template>
<script>
  import {
    mapState
  } from 'vuex'
  import {emoji} from '@/utils/emoji.js'
export default {
    name:'material-store',
    data(){
        return{
            type:'sendText',
            text:'',
            textDataList:[],
            imgList:[],
            videoList:[],
            linkList:[],
            appList:[],
            emojiShow:false,
            loading:false,
            imgLoading:false,
            brandLoading:false,
            videoLoading:false,
            showAddText:false,
            showAddImg:false,
            showAddVideo:false,
            showAddLink:false,
            showAddApp:false,
            previewImgVisible:false,
            previewVideoVisible:false,
            isChooseClick:false,
            previewImage:'',
            previewVideo:'',
            activeLink:-1,
            emojiList:emoji,
            searchTitle:'',
            selectedRowKeys:[],
            selectdList:[],
            selectImgList:[],
            selectVideoList:[],
            selectLinkList:[],
            selectAppList:[],
            textForm:{
                title:'',
                content:''
            },
            imgForm:{
                title:'',
                content:''
            },
            videoForm:{
                title:'',
                content:''
            },
            linkForm:{
                title:'',
                desc:'',
                content:'',
                image_url:''
            },
            appForm:{
                title:'',
                des:'',
                ghid:'',
                appid:'',
                pagepath:'',
                thumburl:'',
                image:'',
            },
            imgPagination: {
                total: 0,
                pageSize: 30,
                current: 1,
                hasNext:false,
                hasPrev:false
            },
            videoPagination:{
                total: 0,
                pageSize: 30,
                current: 1,
                hasNext:false,
                hasPrev:false
            },
            linkPagination:{
                total: 0,
                pageSize: 10,//每页中显示10条数据
                current: 1,
                hasNext:false,
                hasPrev:false
            },
            appPagination:{
                total: 0,
                pageSize: 10,//每页中显示10条数据
                current: 1,
                hasNext:false,
                hasPrev:false
            },
            textPagination: {
                total: 0,
                pageSize: 10,//每页中显示10条数据
                current: 1,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ["5","10", "20"],//每页中显示的数据
                showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
            },
            textColumns:[
                {
                    title: '序号',
                    width: 80,
                    align:'center',
                    customRender: (value, row, index) => `${(this.textPagination.current - 1) * this.textPagination.pageSize + index + 1}`,
                },
                {
                    title: '标题',
                    align:'center',
                    ellipsis:true,
                    width:100,
                    dataIndex: 'title',
                },
                {
                    title: '内容',
                    align:'center',
                    ellipsis:true,
                    dataIndex: 'content',
                },
                {
                    title: '时间',
                    dataIndex: 'create_time',
                    ellipsis:true,
                    width:180,
                    align:'center',
                    scopedSlots: { customRender: "time" },
                },
                {
                    title: "操作",
                    width: 120,
                    align:'center',
                    scopedSlots: { customRender: "operation" },
                },
            ],

        }
    },
    computed: {
        ...mapState(['mqttInit','user'])
    },
    watch:{
        mqttInit:{
            handler(val){
                if(val){
                    this.handleGetList()
                }
            },
            immediate:true
        },
        type(){
            this.textDataList = []
            this.imgList = []
            this.videoList = []
            this.linkList = []
            this.appList = []
            this.selectedRowKeys =[]
            this.selectImgList = []
            this.selectVideoList = []
            this.selectLinkList = []
            this.selectAppList = []
            this.searchTitle = ""
            this.textPagination.current = 1
            this.imgPagination.current = 1
            this.videoPagination.current = 1
            this.linkPagination.current = 1
            this.appPagination.current = 1
            this.handleGetList()
        }
    },
    methods:{
        addEmoji(str){
           this.textForm.content = this.textForm.content+str
        },
        handleAddText(){
            this.handleAdd()
        },
        //添加素材
        handleAdd(){
            let payload = {
                msgId:'saveMaterial',
                data: {
                    type:this.type,
                    create_user:this.user.account,
                    
                },
            }
            if(this.type==="sendText"){
                payload.data = {...payload.data, ...this.textForm}
            }else if(this.type === "sendImage"){
                payload.data = {...payload.data, ...this.imgForm}
            }else if(this.type==="sendVideo"){
                payload.data = {...payload.data, ...this.videoForm}
            }else if(this.type==='sendLink'){
                 payload.data = {...payload.data, content:this.linkForm}
            }else if(this.type==='sendApp'){
                 payload.data = {...payload.data, content:this.appForm,title:this.appForm.title}
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/materialMgtService/saveMaterial",
                payload,
                },
                (topic, data) => {
                    if(data.code===200){
                        this.$message.success('新增成功')
                        if(this.type==="sendText"){
                            this.textForm = {
                                title:'',
                                content:''
                            }
                            this.searchTitle = ''
                            this.showAddText = false
                        }else if(this.type==="sendImage"){
                            this.imgForm = {
                                title:'',
                                content:''
                            }
                            this.searchTitle = ''
                            this.showAddImg = false
                        }else if(this.type==="sendVideo"){
                            this.videoForm = {
                                title:'',
                                content:''
                            }
                            this.searchTitle = ''
                            this.showAddVideo = false
                        }else if(this.type==="sendLink"){
                            this.linkForm = {
                                title:'',
                                desc:'',
                                content:'',
                                image_url:''
                            }
                            this.searchTitle = ''
                            this.showAddLink = false
                        }else if(this.type==="sendApp"){
                            this.appForm = {
                                title:'',
                                desc:'',
                                ghid:'',
                                appid:'',
                                pagepath:'',
                                thumburl:'',
                                image:''
                            }
                            this.searchTitle = ''
                            this.showAddApp = false
                        }
                        
                        this.handleGetList()
                    }
                },
                this
            );
        },
        //查询素材
        handleGetList(){
            let payload = {
                    msgId:'searchMaterialByPage',
                    data: {
                        type:this.type,
                        title:this.searchTitle
                    },
                }
            if(this.type==="sendText"){
                payload.data = {
                    ...payload.data,
                    ...{pageNum:this.textPagination.current,
                        pageSize:this.textPagination.pageSize}
                }
            }else if(this.type==="sendImage"){
                payload.data = {
                    ...payload.data,
                    ...{pageNum:this.imgPagination.current,
                        pageSize:this.imgPagination.pageSize,}
                }
            }else if(this.type==="sendVideo"){
                payload.data = {
                    ...payload.data,
                    ...{pageNum:this.videoPagination.current,
                        pageSize:this.videoPagination.pageSize,}
                }
            }else if(this.type==="sendLink"){
                payload.data = {
                    ...payload.data,
                    ...{pageNum:this.linkPagination.current,
                        pageSize:this.linkPagination.pageSize,}
                }
            }else if(this.type==="sendApp"){
                 payload.data = {
                    ...payload.data,
                    ...{pageNum:this.appPagination.current,
                        pageSize:this.appPagination.pageSize,}
                }
            }
            

            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/materialMgtService/searchMaterialByPage",
                payload,
                },
                (topic, data) => {
                    if(this.type==="sendText"){
                        this.textDataList = data.data.res
                        this.textPagination.count = data.data.count
                    }else if(this.type==="sendImage"){
                        this.imgList = data.data.res
                        this.imgPagination.count = data.data.count
                        this.imgPagination.hasPrev = this.imgPagination.current>1
                        this.imgPagination.hasNext = this.imgPagination.count>=this.imgPagination.current*this.imgPagination.pageSize
                    }else if(this.type==="sendVideo"){
                        this.videoList = data.data.res
                        this.videoPagination.count = data.data.count
                        this.videoPagination.hasPrev = this.videoPagination.current>1
                        this.videoPagination.hasNext = this.videoPagination.count>=this.videoPagination.current*this.videoPagination.pageSize
                    }else if(this.type==="sendLink"){
                        this.linkList = data.data.res
                        this.linkPagination.count = data.data.count
                        this.linkPagination.hasPrev = this.linkPagination.current>1
                        this.linkPagination.hasNext = this.linkPagination.count>=this.linkPagination.current*this.linkPagination.pageSize
                    }else if(this.type==='sendApp'){
                        this.appList = data.data.res
                        this.appPagination.count = data.data.count
                        this.appPagination.hasPrev = this.appPagination.current>1
                        this.appPagination.hasNext = this.appPagination.count>=this.appPagination.current*this.appPagination.pageSize
                    }
                    
                },
                this
            )  
        },
        //修改文件素材
        updateText(row){
            this.textForm.title = row.title
            this.textForm.content = row.content
            this.textForm.id = row._id
            this.showAddText = true
        },
        //删除素材
        handleDelete(item){
            this.$confirm({
              title: '提示',
              content: `是否确认删除？`,
              onOk:()=>{
                  let payload = {
                        msgId:'deleteMaterialByIds',
                        data: {
                            update_user:this.user.account,
                        },
                    }
                    if(this.type==="sendText"){
                        payload.data = {...payload.data,id:this.selectedRowKeys}
                    }else if(this.type==="sendImage"){
                        payload.data = {...payload.data,id:this.selectImgList}
                    }else if(this.type==="sendVideo"){
                        payload.data = {...payload.data,id:this.selectVideoList}
                    }else if(this.type==="sendLink"){
                        payload.data = {...payload.data,id:this.selectLinkList}
                    }else if(this.type==="sendApp"){
                        payload.data = {...payload.data,id:this.selectAppList}
                    }
                    this.$mqtt.doPublish(
                        {
                        pubTopic: "scrm/materialMgtService/deleteMaterialByIds",
                        payload,
                        },
                        (topic, data) => {
                            // console.log(data)
                            if(data.code===200){
                                // if(this.type==="sendText"){
                                    this.$message.success('删除成功')
                                    // this.textSearchTitle = ''
                                // }
                                
                                this.handleGetList()
                            }
                        },
                        this
                    )
              }
            });
              
        },
        //点击查询按钮
        handleClickSearchText(){
            this.textPagination.current = 1
            this.handleGetList()
        },
        //关闭添加文本弹窗
        handleCloseAddText(){
            this.textForm = {title:'',content:''}
        },
        // 文本分页改变
        handleTextTableChange(pagination){
            this.textPagination = pagination
            this.handleGetList()
        },
        //图片视频复选框
        getChecked(item,e){
            if(this.type==="sendImage"){
                if(e.target.checked){
                    this.selectImgList.push(item._id)
                }else{
                    this.selectImgList.splice(this.selectImgList.findIndex(val=>item._id===val),1)
                }
            }else if(this.type==="sendVideo"){
                if(e.target.checked){
                    this.selectVideoList.push(item._id)
                }else{
                    this.selectVideoList.splice(this.selectVideoList.findIndex(val=>item._id===val),1)
                }
            }else if(this.type==="sendLink"){
               if(e.target.checked){
                    this.selectLinkList.push(item._id)
                }else{
                    this.selectLinkList.splice(this.selectLinkList.findIndex(val=>item._id===val),1)
                } 
            }else if(this.type=="sendApp"){
                if(e.target.checked){
                    this.selectAppList.push(item._id)
                }else{
                    this.selectAppList.splice(this.selectAppList.findIndex(val=>item._id===val),1)
                } 
            }
            
        },
        //关闭添加窗口
        handleCloseAddImg(){
            this.imgForm = {title:'',content:''}
        },
        //关闭视频添加
        handleCloseAddVideo(){
            this.videoForm = {title:'',content:''}
        },
        //关闭链接添加
        handleCloseAddLink(){
            this.linkForm = {title:'',desc:'',image_url:'',content:''}
        },
        //关闭小程序添加
        handleCloseAddApp(){
            this.appForm = {
                title:'',
                desc:'',
                ghid:'',
                appid:'',
                pagepath:'',
                thumburl:'',
                image:''
                }
        },
        //点击图片查询按钮
        handleClickSearchImg(){
            this.imgPagination.current = 1
            this.handleGetList()
        },
        //点击视频查询按钮
        handleClickSearchVideo(){
            this.videoPagination.current = 1
            this.handleGetList()
        },
        //点击链接查询
        handleClickSearchLink(){
            this.linkPagination.current = 1
            this.handleGetList()
        },
        //点击小程序查询
        handleClickSearchApp(){
            this.appPagination.current = 1
            this.handleGetList()
        },
        //获取下一页
        handleGetImgsNextPage(){
            this.imgPagination.current ++
            this.handleGetList()
        },
        //视频下一页
        handleGetVideosNextPage(){
            this.videoPagination.current ++
            this.handleGetList()
        },
        //链接下一页
        handleGetLinksNextPage(){
            this.linkPagination.current ++
            this.handleGetList()
        },
        //小程序下一页
        handleGetAppsNextPage(){
            this.appPagination.current ++
            this.handleGetList()
        },
        //获取上一页
        handleGetImgsPrevPage(){
            this.imgPagination.current --
            this.handleGetList()
        },
        //视频上一页
        handleGetVideosPrevPage(){
            this.videoPagination.current --
            this.handleGetList()
        },
        //链接上一页
        handleGetLinksPrevPage(){
            this.linkPagination.current --
            this.handleGetList()
        },
        //小程序上一页
        handleGetAppsPrevPage(){
            this.appPagination.current --
            this.handleGetList()
        },
        //上传图片响应
        handleImgChange(info){
            if (info.file.status === 'uploading') {
                this.imgLoading = true;
                return;
            }
            if (info.file.status === 'done') {
                this.imgLoading = false;
                if(this.type==="sendImage"){
                    this.imgForm.content = info.file.response.url
                }else if(this.type === "sendLink"){
                    this.linkForm.image_url = info.file.response.url
                }else if(this.type === "sendApp"){
                    this.appForm.image = info.file.response.url
                }
                
            }
        },
        //上传图片响应
        handleBrandImgChange(info){
            if (info.file.status === 'uploading') {
                this.brandLoading = true;
                return;
            }
            if (info.file.status === 'done') {
                this.brandLoading = false;
                this.appForm.thumburl = info.file.response.url
                
                
            }
        },
        //上传视频响应
        handleVideoChange(info){
            if (info.file.status === 'uploading') {
                this.videoLoading = true;
                return;
            }
            if (info.file.status === 'done') {
                this.videoLoading = false
                this.videoForm.content = info.file.response.url
            }
        },
        //确定添加图片
        handleAddImg(){
            this.handleAdd()
        },
        //确定添加视频
        handleAddVideo(){
            this.handleAdd()
        },
        //确定添加链接
        handleAddLink(){
            this.handleAdd()
        },
        //确定添加链接
        handleAddApp(){
            this.handleAdd()
        },
        beforeUpload(){

        },
        handlePreview(item) {
            if(this.type === "sendImage"){
                this.previewImage = item.content
                this.previewImgVisible = true
            }else if(this.type==="sendVideo"){
                this.previewVideo = item.content
                this.previewVideoVisible = true
            }
        },
        handleChange() {
            
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        onSelectChange(selectedRowKeys,selectedRows){
            // console.log(selectedRowKeys.length,selectedRows.length)
            this.selectedRowKeys = selectedRowKeys;
            let empty = this.selectdList.concat(selectedRows)
            let obj = {}
            empty = empty.reduce((cur,next)=>{
                obj[next.userid] ? "" : obj[next.userid] = true && cur.push(next);
                return cur;
            },[])
            
            empty = empty.filter(item=>{
                return this.selectedRowKeys.includes(item.userid)
            })
            this.selectdList = empty
        
        },
        updateTabActive(val){
            this.isChooseClick = false
            this.type = val
            this.textSearchTitle = ''
        }
    }
}
</script>
<style lang="scss" scoped>
.tips{
    color: #FECD51;
    text-align: center;
}
.emoji-view{
    position: absolute;
    height: 150px;
    width: 150px;
    top: 250px;
    left: 140px;
    background-color: #fff;
    border: 1px solid;
    border-color: #eee;
    border-radius: 3px;
    padding: 5px;
    overflow-y: scroll;
    .emoji-list{
        font-size: 20px;
        letter-spacing: 4px;
        .emoji-item{
            font-style: initial !important;
            padding-left: 3px;
            padding-top: 1px;
            width: 30px;
            height: 30px;
        }
        .emoji-item:hover{
            background: #e7e7e7;
        }
    }
}
    .page-main{
        height: 100%;
        width: 100%;
        display: flex;
        border:1px solid #eee;
        // border-radius:8px;
        .left-menu{
            width: 160px;
            height: 100%;
            border-right:1px solid #eee;
            // border-radius:8px;
            .menu-item{
                height: 50px;
                text-align: center;
                line-height: 50px;
                color: #333;
                cursor: pointer;
            }
            // .menu-item:first-child{
                // border-top-left-radius: 8px;
            // }
            .menu-item.active{
                color: #2593FC;
                background: #E7F7FF;
            }
        }
        .main-content{
            // border-radius: 0px 8px 8px 0px;
            flex:1;
            height: 100%;
            overflow-y: auto;
            // background: #efefef;
            .text-content{
                padding: 40px;
                
                .text-title{
                    margin-bottom: 20px;
                    text-align: right;
                }
                .btn{
                    margin-top: 20px;
                }
            }
            .img-content,.video-content{
                padding: 30px;
                position: relative;
                .btn-view{
                    margin: 20px 0px;
                    text-align: right;
                    width: 700px;
                    margin-right: calc((100% - 700px)/2);
                }
                .img-list{
                    position: absolute;
                    z-index:100;
                    left: 0;
                    right: 0;
                    margin: 0px auto;
                    width: 700px;
                    height: 350px;
                    display: flex;
                    flex-wrap: wrap;
                    overflow: auto;
                    .img-item{
                        position: relative;
                        width: 100px;
                        height: 120px;
                        border: 1px solid #efefef;
                        border-radius: 6px;
                        text-align: center;
                        margin: 0px 8px 8px 0px;
                        .img{
                            width: 80px;
                            height: 80px;
                            margin-top: 10px;
                        }
                        .title{
                            font-size: 12px;
                            padding: 0 5px;
                            line-height: 30px;
                            white-space:nowrap;
                            overflow:hidden;
                            text-overflow:ellipsis;
                        }
                        .check-view{
                            position: absolute;
                            right: 10px;
                            top:10px;
                        }
                    }
                }
            }
            .link-content{
                padding: 30px;
                .btn-view{
                    margin: 20px 0px;
                    text-align: right;
                    width: 700px;
                    margin-right: calc((100% - 700px)/2);
                }
                .main{
                    width: 750px;
                    height: 350px;
                    overflow: auto;
                    display: flex;
                    flex-wrap: wrap;
                }
                .item{
                    margin-bottom: 20px;
                    margin-right: 20px;
                    position: relative;
                    .link-card{
                        width: 220px;
                        border-radius: 5px;
                        .description{
                            width: 170px;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            word-break: break-all;
                            word-wrap: break-word;
                            overflow: hidden;
                        }
                        .link{
                            color: #2593FC;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            word-break: break-all;
                            word-wrap: break-word;
                            overflow: hidden;
                        }
                    }
                    .check-view{
                            position: absolute;
                            right: 10px;
                            top:10px;
                        }
                    .modal{
                        width: 220px;
                        height: 100%;
                        z-index: 100000;
                        top:0;
                        left: 0;
                        position: absolute;
                        background: rgba(#000, 0.5);
                        color: #fff;
                        font-size: 15px;
                         word-wrap:break-word;
                         padding:100px 10px 10px 10px;
                         .icon-del{
                             font-size: 24px;
                             position: absolute;
                             top: 10px;
                             right: 10px;
                         }

                    }
                }  
                
            }
            .app-content{
                padding: 30px;
                .btn-view{
                    margin: 20px 0px;
                    text-align: right;
                    width: 700px;
                    margin-right: calc((100% - 700px)/2);
                }
                .main{
                    width: 750px;
                    height: 350px;
                    overflow: auto;
                    display: flex;
                    flex-wrap: wrap;
                }
                .item{
                    margin-bottom: 20px;
                    margin-right: 20px;
                    position: relative;
                    .app-card{
                        width: 220px;
                        height: 180px;
                        padding: 10px;
                        background-color: #eee;
                        border-radius: 8px;
                        .title{
                            height: 30px;
                            line-height: 30px;
                            display: flex;
                            align-items: center;
                            .logo-img{
                                width: 20px;
                                height: 20px;
                                border-radius: 50%;
                            }
                            .app-name{
                                font-size: 13px;
                                color:#999;
                                padding-left: 10px;
                            }
                        }
                        .content{
                            .desc{
                                color: #333;
                                font-size: 13px;
                                height: 19px;
                            }
                            .main-img{
                                width: 100%;
                                height: 100px;
                            }
                        }
                        
                    }
                    .check-view{
                        position: absolute;
                        right: 10px;
                        top:10px;
                    }
                    
                }  
            }
        }
    }
    ::v-deep .ant-modal-body{
        overflow: auto;
    }
    .v-enter,
    .v-leave-to{
        opacity: 0;
    }
    .v-enter-to,.v-leave{
        opacity: 1;
    }
    .v-enter-active,
    .v-leave-active{
        transition: all 1s;
    }
    
</style>